import React, { Component } from "react";
import {Link} from 'react-router-dom';
import LayoutContext from "../context/layout.context";
export default class Header extends Component {
  render() {
    return (
      <LayoutContext.Consumer>
        {(value) => (
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <button
                type="button"
                id="sidebarCollapse"
                className="btn btn-info"
                onClick={value.onOpenHandle}
              >
                {value.open === true ? (
                  <i className="fas fa-angle-double-left"></i>
                ) : (
                  <i className="fas fa-angle-double-right"></i>
                )}
              </button>
              <button
                className="btn btn-dark d-inline-block d-lg-none ml-auto"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fas fa-align-justify" />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="nav navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                    <i className="far fa-user-circle" />
                      {value.name}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        )}
      </LayoutContext.Consumer>
    );
  }
}
