import React from "react";
export default function Loading(props) {
  if(props.fullScreen) {
    return (
        <div className="loading" style={{position: "fixed"}}>
          <div className="loading_content">
            <span>Loading....</span>
          </div>
        </div>
      );
  }

  if(props.block) {
    return (
      <div className="loading" style={{"position": "absolute"}}>
        <div className="loading_content">
          <span>Loading....</span>
        </div>
      </div>
    );
  }

  return (
      <div className="loading_small">
          <span>Loading...</span>
      </div>
  )
}
