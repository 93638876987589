import React from 'react';

const Users = React.lazy(() => import('./users/index.users'));
const Projects = React.lazy(() => import('./projects/index.projects'));
const Furniture = React.lazy(() => import('./furniture/index.furniture'));
const Section = React.lazy(() => import('./main/index.main'));
const About = React.lazy(() => import('./view/about'));
const Counter = React.lazy(() => import('./components/counter'));
const CounterDetail = React.lazy(() => import('./components/counterDetail'));
const DashboardClient = React.lazy(() => import('./dashboard/client'));
const DashboardProduct = React.lazy(() => import('./dashboard/products'));

const routes = [
    { path: '/', exact: true, name: 'Home', component: Projects },
    { path: '/users', exact: false, name: 'Users', component: Users },
    { path: '/projects', exact: false, name: 'Projects', component: Projects },
    { path: '/furniture', exact: false, name: 'Furniture', component: Furniture },
    { path: '/home', exact: false, name: 'Section', component: Section },
    { path: '/about', exact: false, name: 'About', component: About },
    { path: '/counter', exact: true, name: 'Dashboard', component: Counter },
    { path: '/counter/:id', exact: true, name: 'Dashboard Detail', component: CounterDetail },
    { path: '/dashboard/client', exact: true, name: 'Dashboard Client', component: DashboardClient },
    { path: '/dashboard/products', exact: true, name: 'Dashboard Products', component: DashboardProduct },
]

export default routes;