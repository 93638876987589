import React, { Component } from "react";
import {Link} from 'react-router-dom';
import cookie from 'react-cookies';
import "./style.login.css";
import {Helmet} from "react-helmet";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }
  onChangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmitHandle(e) {
      e.preventDefault();
      this.setState({loading: true});
      
      // form data
      let formData = new FormData();
      formData.append("email", this.state.email);
      formData.append("password", this.state.password);

      // make request
      fetch(process.env.REACT_APP_API + '/api/auth/login.php', {
        method: 'POST',
        body: formData,
      }).then(async (r) => {
          this.setState({loading: false});
          if(r.status === 401) alert(await r.text());
          if(r.status === 400) alert(await r.text());
          if(r.status === 200) return r.json();
      }).then(response => {
         if(response) {
            cookie.save('Token', response);
            window.location.href = "/";
         }
      }).catch(error => {
          alert('Error processing request.');
          this.setState({loading: false});
      });
  }
  render() {
    return (
      <div className="login">
        <Helmet>
          <title>Login</title>
        </Helmet>
        <div className="login_form">
          <form onSubmit={this.onSubmitHandle.bind(this)} autoComplete="off">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                placeholder="Enter email"
                required={true}
                value={this.state.email}
                onChange={this.onChangeHandler}
                autoComplete="off"
                disabled={this.state.loading}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                placeholder="Enter password"
                required={true}
                value={this.state.password}
                onChange={this.onChangeHandler}
                autoComplete="off"
                disabled={this.state.loading}
              />
            </div>
            <div className="form-group">
              <button disabled={this.state.loading} type="submit" className="btn btn-primary">{this.state.loading ? "Processing..." : "Submit"}</button>
            </div>
            <div className="text-center">
                <Link to="/forgot">Forgot Password?</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
