import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import "./style.login.css";
export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm_password: "",
      password: "",
      loading: false,
      token: this.props.match.params.id,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }
  onChangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmitHandle(e) {
    e.preventDefault();
    this.setState({ loading: true });

    // form data
    let formData = new FormData();
    formData.append("password", this.state.password);
    formData.append("confirm_password", this.state.confirm_password);

    // make request
    fetch(process.env.REACT_APP_API + "/api/auth/change_password.php", {
      method: "POST",
      body: formData,
      headers: {
        'Token': this.state.token,
      },
    })
      .then(async (r) => {
        this.setState({ loading: false });
        if (r.status === 401) {
          alert("The username or password is incorrect.");
        }
        if (r.status === 400) alert(await r.text());
        if (r.status === 200) {
          cookie.save("Token", await r.json());
          window.location.href = "/";
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        alert("Error processing request.");
      });
  }
  render() {
    return (
      <div className="login">
        <div className="login_form">
          <form onSubmit={this.onSubmitHandle.bind(this)} autoComplete="off">
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                placeholder="Enter password"
                required={true}
                value={this.state.password}
                onChange={this.onChangeHandler}
                autoComplete="off"
                disabled={this.state.loading}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Confirm Password</label>
              <input
                type="password"
                name="confirm_password"
                id="confirm_password"
                className="form-control"
                placeholder="Enter confirmation password"
                required={true}
                value={this.state.confirm_password}
                onChange={this.onChangeHandler}
                autoComplete="off"
                disabled={this.state.loading}
              />
            </div>
            <div className="form-group">
              <button
                disabled={this.state.loading}
                type="submit"
                className="btn btn-primary"
              >
                {this.state.loading ? "Processing..." : "Submit"}
              </button>
            </div>
            <div className="text-center">
              <Link to="/login">Login</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
