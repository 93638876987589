import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.login.css";
export default class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }
  onChangeHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmitHandle(e) {
    e.preventDefault();
    let formData = new FormData();
    formData.append("email", this.state.email);

    this.setState({ loading: true });

    fetch(process.env.REACT_APP_API + "/api/auth/forgot.php", {
      method: "POST",
      body: formData,
    })
      .then(async (r) => {
        this.setState({ loading: false });
        if (r.status === 200) {
          this.setState({ email: "" });
          alert("The email was sent successfully.");
        }
        if(r.status === 401) alert(await r.text());
        if (r.status === 400) alert(await r.text());
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <div className="login">
        <div className="login_form">
          <form onSubmit={this.onSubmitHandle.bind(this)} autoComplete="off">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                placeholder="Enter email account"
                required={true}
                value={this.state.email}
                onChange={this.onChangeHandler}
                autoComplete="off"
                disabled={this.state.loading}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={this.state.loading}
              >
                {this.state.loading ? "Processing..." : "Submit"}
              </button>
            </div>
            <div className="text-center">
              <Link to="/login">Login</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
