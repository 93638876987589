import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import cookie from 'react-cookies';

import IndexHome from './components/layout/index.layout';
import IndexLogin from './components/login/index.login';
import ForgotLogin from './components/login/forgot.login';
import ChangePassword from './components/login/change.login';
import Activate from './components/login/activate.login';

function IsLogin() {
  let token = cookie.load('Token');
  if(token === '' || token  === undefined || token === null) return false;
  return true;
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => IsLogin() ? children : (<Redirect to={{ pathname: "/login", state: { from: location } }}></Redirect>)}
    />
  )
}

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={IndexLogin}/> 
        <Route exact path="/forgot" component={ForgotLogin}/>
        <Route exact path="/activate/:id" component={Activate}/>
        <Route exact path="/change_password/:id" component={ChangePassword}/>
        <PrivateRoute path="/"><IndexHome></IndexHome></PrivateRoute>
      </Switch>
    </BrowserRouter>
    );
  }
}