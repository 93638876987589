import React, { Component } from "react";
import SideBar from "./sidebar.layout";
import Header from "./header.layout";
import cookie from "react-cookies";
import routes from "../routes";
import Loading from "../components/loading";

import "./style.layout.css";

/* Import Context */
import LayoutContext from "../context/layout.context";
import { Switch, Route } from "react-router-dom";

export default class Layout extends Component {
  constructor(props) {
    super(props);

    this.onOpenHandle = () => {
      this.setState((state) => ({
        open: !state.open,
      }));
    };

    this.onLogout = () => {
      cookie.remove("Token");
      window.location.href = "/login";
    };

    this.state = {
      open: true,
      onOpenHandle: this.onOpenHandle,
      name: "Guest",
      onLogout: this.onLogout,
      loading: true,
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API + "/api/user/getinfo.php", {
      method: "post",
      headers: {
        Token: cookie.load("Token"),
      },
    })
      .then((r) => {
        if (r.status === 200) return r.json();
        if (r.status === 401) {
          window.open("/login", "_self");
        }
      })
      .then((res) => {
        if (res) {
          this.setState({
            name: res.name + " " + res.last_name,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }

  render() {
    if (this.state.loading) {
      return <Loading block={true} />;
    }
    return (
      <LayoutContext.Provider
        value={{
          open: this.state.open,
          onOpenHandle: this.state.onOpenHandle,
          name: this.state.name,
          onLogout: this.state.onLogout,
        }}
      >
        <div className="wrapper">
          <SideBar></SideBar>
          {/* Page Content Holder */}
          <div id="content" className="content">
            <Header></Header>
            <React.Suspense fallback={<Loading block={true}></Loading>}>
              <Switch>
                {routes &&
                  routes.map((item, index) => {
                    return (
                      <Route
                        exact={item.exact}
                        key={index}
                        path={item.path}
                        component={(props) => (
                          <item.component {...props}></item.component>
                        )}
                      ></Route>
                    );
                  })}
              </Switch>
            </React.Suspense>
          </div>
        </div>
      </LayoutContext.Provider>
    );
  }
}
